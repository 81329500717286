import React from 'react';
import { string, shape } from 'prop-types';
import { Image } from 'nordic/image';

import Section from '../section';

const HREF = './help-email/icon_email.svg';

const HelpEmail = ({
  type,
  title: { text, accessibilityText },
  action: { target },
  class_name,
  deviceType,
}) => {
  const DESKTOP = 'desktop';
  const Element = deviceType === DESKTOP ? 'div' : Section;
  const ACCESSIBILITY_TEXT_DEFAULT = 'email';

  return (
    <Element className={type}>
      <a
        className={`${class_name}__link`}
        href={target || '#'}
        target="_blank"
        rel="noreferrer"
      >
        <Image
          className={`${class_name}__icon`}
          src={HREF}
          alt={accessibilityText || ACCESSIBILITY_TEXT_DEFAULT}
          lazyload="on"
        />
        <span className={`${class_name}__email`}>{text}</span>
      </a>
    </Element>
  );
};

HelpEmail.propTypes = {
  type: string.isRequired,
  action: shape({
    target: string.isRequired,
  }).isRequired,
  title: shape({
    text: string.isRequired,
    accessibilityText: string,
  }).isRequired,
  class_name: string.isRequired,
  deviceType: string.isRequired,
};

export default HelpEmail;
